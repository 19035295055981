.card {
    background-color: #363a42;
    width: 30em;
    margin: 1em;
    padding: 0 2em;
    box-shadow: 5px 5px 20px black;
    border-top: 4px solid #b0b3b5;
    border-radius: .75em;
    transition: .75s all ease-in-out;


    .upperSection {
        margin-top: 2em;
        display: flex;
        align-items: center;
        //justify-content: center;

        #projectAvatar {
            margin-right: 1em;
            box-shadow: 2px 2px 5px black;
            border-radius: .25em;
        }

        h4 {

            margin: 0;
            line-height: 1.5em;
        }

    }

    .middleSection {
        h2 {
            text-align: center;
        }

        #pillsContainer {
            display: flex;
            justify-content: space-evenly;
            font-weight: bolder;
        }

        #pill {
            background-color: #5078bf;
            padding: 0 1em;
            border-radius: .75em;
            font-weight: bolder;
            border: 2px solid whitesmoke;
        }

        #cardHR {
            background-color: #20212358;
            margin-top: 1.5em;
            padding: .05em;
            border: none;
        }
    }

    .footerSection {
        display: flex;
        justify-content: space-between;
    }
}

.card:hover {
    box-shadow: 0px 3px 15px 12px rgba(0, 0, 0, 0.6);
    transition: .25s all ease-in-out;
}