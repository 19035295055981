@media all and (min-width:320px) and (max-width:600px) {
    .flexContainer {
        font-size: .8em;
        margin: 1em 0;
    }

    #contact {
        img {
            display: none;
        }
    }

    #footerText {
        :nth-child(2) {
            display: none;
        }
    }
}