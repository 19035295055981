.flexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;


    #footerText,
    #contact {
        display: flex;

        :nth-child(n) {
            margin: 1em;
        }
    }

    #contact {
        align-items: center;

        :nth-child(n) {
            margin: 1em;
        }

        img {
            width: 2em;
        }

        h5 {
            filter: opacity(.35);
        }
    }
}