.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    // grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 80px;
    justify-items: center;
    transition: 1s all ease-in-out;
}

#allJobs {
    padding: 2em;
}

.inputContainer {

    #searchInput {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 70%;
        left: 50%;
        padding: 1.5em;
        width: 70em;
        background-color: #202123;
        border-radius: 1em;
        border: 2px solid #363a42;
        color: #b0b3b5;
        font-weight: bolder;
    }
}

#projectsHR {
    width: 95%;
    padding: .1em;
    background: #363a42;
    outline: none;
    border: none;
}