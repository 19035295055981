@media all and (max-width:400px) {
    .banner #bannerText {
        font-size: 1.25em !important;
    }

    #bannerCaption {
        display: none;
    }

}


@media all and (min-width:320px) and (max-width:600px) {

    #navText,
    #avatar {
        display: none !important;
    }

    .banner #bannerText {
        top: 9em;
        font-size: 1.5em;

        h4 {
            white-space: pre;
        }

        #bannerCaption {
            display: none !important;
        }
    }
}

@media all and (min-width:601px) and (max-width:1000px) {

    .banner #BannerBig {
        height: 23.5em;
    }

    .nav #navText {
        display: none;
    }

    .banner #bannerText {
        top: 10em;
        white-space: pre;
        font-size: 2em;
    }

    #bannerCaption {
        display: none;
    }
}

@media all and (min-width:1001px) and (max-width:1366px) {

    .banner #BannerBig {
        height: 23.5em;
    }


    .banner #bannerText {
        top: 10em;
        white-space: pre;
        font-size: 2em;
    }
}