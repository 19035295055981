@media all and(max-width:400px) {
    #pill {
        font-size: .85em;
    }
}


@media all and (min-width:320px) and (max-width:600px) {

    .card {
        width: 75vw;
    }

    .card .middleSection #pill {
        padding: 0 .25em;
        border-radius: .75em;

    }

    #pillsContainer {

        font-weight: lighter;
    }
}