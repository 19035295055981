@media all and(max-width:400px) {

    .inputContainer #searchInput {
        top: 25em !important;
        width: 25em !important;

    }
}


@media all and (min-width:320px) and (max-width:600px) {

    .inputContainer #searchInput {
        top: 27em;
        width: 28em;

    }

    #allJobs {
        padding: 2em 0.5em;
    }

    .gridContainer {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    }
}

@media all and (min-width:601px) and (max-width:1000px) {
    .inputContainer #searchInput {
        top: 38.5em;
        width: 47em;

    }
}

@media all and (min-width:1001px) and (max-width:1366px) {
    .inputContainer #searchInput {
        top: 38.5em;
        width: 47em;
    }
}